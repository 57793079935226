// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import FullTeamVisibility from "../../blocks/FullTeamVisibility/src/FullTeamVisibility";
import AdvancedSearch from "../../blocks/AdvancedSearch/src/AdvancedSearch";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator";
import Calendar from "../../blocks/Calendar/src/Calendar";
import Sorting from "../../blocks/sorting/src/Sorting";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import TargetedFeed from "../../blocks/TargetedFeed/src/TargetedFeed";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import SplitViewInterface from "../../blocks/SplitViewInterface/src/SplitViewInterface";
import ApiIntegration from "../../blocks/ApiIntegration/src/ApiIntegration";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";
import GraphicalCharts from "../../blocks/GraphicalCharts/src/GraphicalCharts";
import Settings from "../../blocks/Settings/src/Settings";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import InstagramFeedDisplay from "../../blocks/InstagramFeedDisplay/src/InstagramFeedDisplay";
import PaidContent from "../../blocks/PaidContent/src/PaidContent";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import PromoteContent from "../../blocks/PromoteContent/src/PromoteContent";
import Notes from "../../blocks/Notes/src/Notes";
import PreviewVideo from "../../blocks/PreviewVideo/src/PreviewVideo";
import Analytics from "../../blocks/Analytics/src/Analytics";
import PaypalIntegration from "../../blocks/PaypalIntegration/src/PaypalIntegration";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import TwitterFeedsDisplay from "../../blocks/TwitterFeedsDisplay/src/TwitterFeedsDisplay";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Notifications from "../../blocks/Notifications/src/Notifications";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import PricingEngine from "../../blocks/PricingEngine/src/PricingEngine";
import PhotoEditingTool from "../../blocks/PhotoEditingTool/src/PhotoEditingTool";
import ImagePicker from "../../blocks/ImagePicker/src/ImagePicker";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import UserGroups from "../../blocks/UserGroups/src/UserGroups";
import AutomatedPublishing from "../../blocks/AutomatedPublishing/src/AutomatedPublishing";
import UploadMedia from "../../blocks/UploadMedia/src/UploadMedia";
import PrivacySettings from "../../blocks/PrivacySettings/src/PrivacySettings";
import HashtagGenerator from "../../blocks/HashtagGenerator/src/HashtagGenerator";
import Share from "../../blocks/Share/src/Share";
import CustomisableUserSubscriptions from "../../blocks/CustomisableUserSubscriptions/src/CustomisableUserSubscriptions";
import CustomAdvertisements from "../../blocks/CustomAdvertisements/src/CustomAdvertisements";
import FreeTrial from "../../blocks/FreeTrial/src/FreeTrial";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Maps from "../../blocks/Maps/src/Maps";
import StatisticsReports from "../../blocks/StatisticsReports/src/StatisticsReports";
import Location from "../../blocks/Location/src/Location";
import PushNotifications from "../../blocks/PushNotifications/src/PushNotifications";
import GroupChat from "../../blocks/GroupChat/src/GroupChat";
import DynamicContent from "../../blocks/DynamicContent/src/DynamicContent";
import RecommendationEngine from "../../blocks/RecommendationEngine/src/RecommendationEngine";
import Payments from "../../blocks/Payments/src/Payments";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Scheduling from "../../blocks/Scheduling/src/Scheduling";
import DragDropInterface from "../../blocks/DragDropInterface/src/DragDropInterface";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import CustomForm from "../../blocks/CustomForm/src/CustomForm";
import AdHocReporting from "../../blocks/AdHocReporting/src/AdHocReporting";
import FacebookFeedsDisplay from "../../blocks/FacebookFeedsDisplay/src/FacebookFeedsDisplay";
import FriendList from "../../blocks/FriendList/src/FriendList";
import EmailDeliverability from "../../blocks/EmailDeliverability/src/EmailDeliverability";
import SentimentAnalysis from "../../blocks/SentimentAnalysis/src/SentimentAnalysis";
import LiveChat from "../../blocks/LiveChat/src/LiveChat";



const routeMap = {
FullTeamVisibility:{
 component:FullTeamVisibility,
path:"/FullTeamVisibility"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
TaskAllocator:{
 component:TaskAllocator,
path:"/TaskAllocator"},
Calendar:{
 component:Calendar,
path:"/Calendar"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
TargetedFeed:{
 component:TargetedFeed,
path:"/TargetedFeed"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
SplitViewInterface:{
 component:SplitViewInterface,
path:"/SplitViewInterface"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
GraphicalCharts:{
 component:GraphicalCharts,
path:"/GraphicalCharts"},
Settings:{
 component:Settings,
path:"/Settings"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
InstagramFeedDisplay:{
 component:InstagramFeedDisplay,
path:"/InstagramFeedDisplay"},
PaidContent:{
 component:PaidContent,
path:"/PaidContent"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
PromoteContent:{
 component:PromoteContent,
path:"/PromoteContent"},
Notes:{
 component:Notes,
path:"/Notes"},
PreviewVideo:{
 component:PreviewVideo,
path:"/PreviewVideo"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
PaypalIntegration:{
 component:PaypalIntegration,
path:"/PaypalIntegration"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
TwitterFeedsDisplay:{
 component:TwitterFeedsDisplay,
path:"/TwitterFeedsDisplay"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
PricingEngine:{
 component:PricingEngine,
path:"/PricingEngine"},
PhotoEditingTool:{
 component:PhotoEditingTool,
path:"/PhotoEditingTool"},
ImagePicker:{
 component:ImagePicker,
path:"/ImagePicker"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
UserGroups:{
 component:UserGroups,
path:"/UserGroups"},
AutomatedPublishing:{
 component:AutomatedPublishing,
path:"/AutomatedPublishing"},
UploadMedia:{
 component:UploadMedia,
path:"/UploadMedia"},
PrivacySettings:{
 component:PrivacySettings,
path:"/PrivacySettings"},
HashtagGenerator:{
 component:HashtagGenerator,
path:"/HashtagGenerator"},
Share:{
 component:Share,
path:"/Share"},
CustomisableUserSubscriptions:{
 component:CustomisableUserSubscriptions,
path:"/CustomisableUserSubscriptions"},
CustomAdvertisements:{
 component:CustomAdvertisements,
path:"/CustomAdvertisements"},
FreeTrial:{
 component:FreeTrial,
path:"/FreeTrial"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Maps:{
 component:Maps,
path:"/Maps"},
StatisticsReports:{
 component:StatisticsReports,
path:"/StatisticsReports"},
Location:{
 component:Location,
path:"/Location"},
PushNotifications:{
 component:PushNotifications,
path:"/PushNotifications"},
GroupChat:{
 component:GroupChat,
path:"/GroupChat"},
DynamicContent:{
 component:DynamicContent,
path:"/DynamicContent"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
Payments:{
 component:Payments,
path:"/Payments"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
DragDropInterface:{
 component:DragDropInterface,
path:"/DragDropInterface"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
CustomForm:{
 component:CustomForm,
path:"/CustomForm"},
AdHocReporting:{
 component:AdHocReporting,
path:"/AdHocReporting"},
FacebookFeedsDisplay:{
 component:FacebookFeedsDisplay,
path:"/FacebookFeedsDisplay"},
FriendList:{
 component:FriendList,
path:"/FriendList"},
EmailDeliverability:{
 component:EmailDeliverability,
path:"/EmailDeliverability"},
SentimentAnalysis:{
 component:SentimentAnalysis,
path:"/SentimentAnalysis"},
LiveChat:{
 component:LiveChat,
path:"/LiveChat"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;